@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
body {
    font-family: "Open Sans", sans-serif;
    margin: 0;
}
.sidebar {
    @include media-breakpoint-up(lg) {
        &.small {
            width: 70px;
            padding-bottom: 10px;

            .subtitle {
                display: none;
            }

            .nav li > a {
                margin-left: 8px;
            }

            .sidebar-wrapper {
                width: 100%;
            }

            .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):after,
            .sidebar-wrapper
                li.active
                > a:not([data-toggle="collapse"])::before {
                display: none;
            }

            li {
                display: inline-block;
                clear: both;
                width: 100%;
                margin-bottom: 10px;
                a {
                    p {
                        display: none;
                    }
                }
            }
        }
    }

    .sidebar-wrapper {
        overflow: hidden;
    }
    .logo {
        display: inline-block;
        margin-top: 15px;
        width: 100%;
        .img {
            width: 95px;
            margin-bottom: 5px;
            float: left;
            margin-right: 5px;
        }

        .subtitle {
            font-size: 12px;
            /* color: gray; */
            font-weight: bold;
            text-transform: uppercase;
            float: right;
            letter-spacing: normal;
        }
    }

    @include media-breakpoint-down(md) {
        &.open {
            transform: translate3d(0, 0, 0);
        }
    }
}

.react-daterange-picker__calendar {
    z-index: 5 !important;
}

.small-open-menu {
    border: none;
    font-size: 30px;
    background: white;
    padding: 0 12px;

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.progress-bar-wrap {
    display: flex;
    flex-direction: row;
    // min-width: 500px;

    .item-wrap {
        cursor: pointer;
    }

    .item-wrap > div:hover {
        transform: scale(1, 1.3);
        box-shadow: 0px 0px 10px gray;
    }

    .item-wrap:first-child > div {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        overflow: hidden;
    }
    .item-wrap:last-child > div {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        overflow: hidden;
    }
}

.show-filter-button {
    display: none;
    @include media-breakpoint-down(md) {
        display: inline-block;
    }
}

.data-grid-header {
    flex-direction: column;
    align-items: flex-end;
    padding: 10px 5px;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    .filters {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    .table-search {
        border-radius: 5px;
        border: 1px solid lightgray;
        padding: 5px;
        min-width: 300px;
    }

    label {
        margin-right: 5px;
        // font-weight: bold;
    }

    .search {
        width: 300px;

        label {
            font-size: 14px;
        }
    }
}

.App {
    .MuiTableSortLabel-root:hover {
        color: black;
    }
    .react-calendar__tile--now {
        background: #d2d2d2;
    }
    .MuiTableCell-head {
        background: #f3f3f3;
        color: #232323;
        font-weight: bold;
    }
    .MuiTableSortLabel-root:focus {
        color: black;
    }

    .MuiTableCell-root {
        padding: 5px 5px 8px 5px;
    }

    .cursor-pointer {
        cursor: pointer;
    }
    .MuiTablePagination-root {
        .MuiIconButton-root {
            // padding: 0 12px;
            display: none;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        color: black;
    }

    .MuiTableSortLabel-root.MuiTableSortLabel-active,
    .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
        .MuiTableSortLabel-icon {
        color: black;
    }
}

.dl-item.floating {
    opacity: 0.5;
}

.gu-mirror {
    // position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    padding: 1em;
}
.gu-hide {
    display: none !important;
}
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
.gu-transit {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
}
.gu-mirror {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
}
span.mention {
    // padding: 3px 3px;
    border-radius: 2px;
    color: #0593e3;
}

.title-close {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background-color: red;
    color: white;
    border-radius: 5px;
    font-size: 12px;
    padding: 3px 10px;

    width: 144px;
    margin-right: 5px;
    font-weight: bold;
}

ul.ql-mention-list {
    list-style: none;
    margin: 0;
    background: #f1f1f1;
    border-radius: 3px;
    padding: 0;
    li {
        padding: 5px 10px;
    }

    li.selected {
        background-color: #cecece;
    }
}

.ql-mention-list-container {
    z-index: 2;
}

.messages-page {
    .breadcrumb {
        margin-bottom: 10px;
    }

    .change-order-items-wrap {
        min-width: 200px;
        background-color: lightgray;
        padding: 5px 5px 1px 5px;
        border-radius: 3px;
        .item {
            background-color: white;
            margin-bottom: 5px;
            border-radius: 3px;
            padding: 5px 10px;
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 10px;
            gap: 10px;
            cursor: grab;
        }
    }

    .change-order-title {
        font-weight: bold;
    }

    .msg-title-input {
        border: none;
        background-color: transparent;
        width: 100%;
    }
    .header {
        display: grid;
        grid-template-columns: auto 1fr 100px auto auto;
        margin-bottom: 0;
        align-items: center;
        gap: 10px;

        .toggle-button {
            background-color: white;
            width: 30px;
            text-align: center;
            font-size: 20px;
        }
    }
    .msg-row {
        background-color: rgb(228, 228, 228);
        padding: 10px;
        margin: 0;
        margin-bottom: 10px;
        border-radius: 3px;
        list-style: none;

        .reorder-icon {
            background-color: white;
            border-radius: 3px;
            width: 30px;
            height: 30px;
            // margin-bottom: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: grab;
        }
    }

    textarea.sms {
        max-width: 100%;
        max-height: 100%;
        height: calc(100% - 66px);
        margin-top: 43px;
        padding: 5px;
    }

    .ql-editor {
        // min-height: 100px;
        height: 100%;
    }

    .flexbox-fix:nth-child(2) {
        display: none !important;
    }

    .picked-color {
        width: 275px;
        height: 0;
        margin-bottom: 10px;
        border-radius: 3px;
    }

    .msg-title {
        background-color: #fbfbfbc4;
        padding: 5px;
        border-radius: 2px;
        color: black;
        margin-bottom: -2px;
        font-size: 15px;
    }

    .chrome-picker {
        width: 275px !important;
        margin-bottom: 0px;
        box-shadow: none !important;
    }

    .twitter-picker {
        box-shadow: none !important;

        > div:nth-child(1) {
            display: none;
        }

        > div:nth-child(3) > div {
            display: none !important;
        }
    }

    .input-grid {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        gap: 15px;
        background-color: white;
        padding: 15px;
        border-radius: 3px;
        margin-top: 10px;

        .subject {
            margin-bottom: 10px;
        }

        h2 {
            font-size: 14px;
        }
    }
}

.branch-name {
    padding-left: 20px;
    font-size: 20px;
    padding-top: 0px;
    background: transparent;
    font-weight: bold;
}

.flexible-modal {
    position: absolute;
    z-index: 1;
    border: 1px solid #ccc;
    background: white;
}
.flexible-modal-mask {
    position: fixed;
    height: 100%;
    background: rgba(55, 55, 55, 0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.flexible-modal-resizer {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: se-resize;
    margin: 5px;
    border-bottom: solid 2px #333;
    border-right: solid 2px #333;
}
.flexible-modal-drag-area {
    background: rgba(22, 22, 333, 0.2);
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: move;
}

.no-msg-lbl {
    width: 83%;
    font-size: 14px;
    padding: 10px 15px;
    text-align: center;
    background-color: #8080806e;
    color: white;
    margin: 10px;
    box-sizing: border-box;
    margin: 25px auto;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
}

.chat-component {
    height: calc(100vh - 30%);
    min-height: 400px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    background: #eaeaea;
    padding: 0;
    width: calc(100vw - 40%);
    box-shadow: 0px 0px 6px #afafaf;
    border-radius: 3px;
    z-index: 10;
    max-width: 95%;

    .remove-btn {
        margin-left: 10px;
        border: none;
        width: 30px;
        color: red;
        border-radius: 2px;
        background-color: transparent;

        &:hover {
            background-color: red;
            color: white;
        }
    }

    &.small {
        min-height: 0;
        position: fixed;
        bottom: 10px;
        right: 10px;
        background: #eaeaea;
        padding: 0;
        width: calc(100vw - 30%);
        box-shadow: 0px 0px 6px #000000;
        border-radius: 3px;
        z-index: 10;
        max-width: 95%;
        top: calc(50% - 150px);
        height: auto;
        bottom: auto;
        left: 0;
        right: 0;
        margin: 0 auto;
        overflow: hidden;
        font-size: 100%;
        z-index: 10000;

        .ql-editor {
            height: 125px;
        }

        .msg-send-wrap {
            flex-direction: column;
            align-items: flex-end;
        }
    }

    @include media-breakpoint-down(md) {
        height: 90vh;
        width: 100%;
    }

    .wo-contact-list {
        width: 25%;
        margin: 0px;
        height: 97%;
        overflow: auto;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                background-color: #eaeaea;
                padding: 10px 5px;
                // border-radius: 3px;
                margin-bottom: 10px;
                font-weight: bold;
                cursor: pointer;

                // &::before {
                //     content: "\f2c1";
                //     font-family: "Font Awesome 5 Free";
                //     margin-right: 0px;
                //     background-color: white;
                //     padding: 3px 7px;
                //     margin: 5px;
                //     border-radius: 50%;
                //     color: #274acd;
                //     position: absolute;
                //     right: 3px;
                //     top: 4px;
                //     bottom: 4px;
                // }
            }

            li.selected {
                background-color: white;
                color: white;
                position: relative;

                // &::after {
                //     opacity: 0.3;
                //     content: "Active";
                //     position: absolute;
                //     font-size: 11px;
                //     right: 7px;
                //     background: white;
                //     padding: 5px;
                //     top: 7px;
                //     border-radius: 3px;
                //     color: black;
                // }
            }
        }
    }

    .wo-title-item {
        float: left;
        padding: 2px 15px;
        background: white;
        margin-right: 3px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
    }

    input {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: none;
    }

    .msg-wrap {
        // height: calc(100% - 173px);
        flex: 1;
        margin-bottom: 1px;
        background-color: white;
        padding: 15px;
        margin-top: 10px;
        overflow: auto;

        .send-msg,
        .received-msg {
            margin: 2px 0;
            clear: both;
            border-radius: 3px;
            width: auto;
            padding: 10px 10px;
        }

        .send-msg {
            background: #00800024;
            float: right;
            // color: white;
        }
        .received-msg {
            background: #e4e4e4;
            float: left;
            color: black;
        }
    }

    .btn-close {
        background-color: #252525;
        margin-right: 15px;
    }

    .msg-send-wrap {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: center;
        background-color: white;
        padding: 0 5px;
        // border-top: 2px solid #eaeaea;

        .editor-wrap {
            flex: 1;
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            padding: 0;
        }
    }

    .ql-container.ql-snow {
        border: none;
    }

    .ql-editor {
        // height: 50px;
        border: 1px solid gainsboro;
        margin: 5px 3px;
        background: #eaeaea;
        border-radius: 3px;
    }
}

.chat-minimized {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 0px 4px black;
    border-radius: 5px;
    width: 303px;
    font-weight: bold;
    cursor: pointer;
}

.history {
    .react-daterange-picker__wrapper {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        align-items: center;
        border: thin solid lightgrey;
        height: 33px;
    }

    .filter-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        flex-direction: row;
        flex-wrap: wrap;
        padding: 5px;

        @media screen and (max-width: 1527px) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }

        @include media-breakpoint-down(lg) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr 1fr;
        }

        @include media-breakpoint-down(xs) {
            grid-template-columns: 1fr;
        }

        .MuiInput-underline:before {
            // display: none;
        }

        > div {
            margin-right: 5px;
            margin-bottom: 5px;
        }
        .filter-input {
            width: 100%;
            // border-radius: 1px;
            // border: 1px solid lightgray;
            // padding: 5px;
        }
    }
}

.open-menu {
    border: none;
    display: none;
    margin: 10px;
    font-size: 20px;

    @include media-breakpoint-down(md) {
        display: inline-block;
    }
}

.open {
    .close-menu {
        display: block;
    }
}
.close-menu {
    display: none;
    position: absolute;
    right: -25px;
    border: none;
    font-size: 20px;
    background-color: white;
}

.history .react-daterange-picker__wrapper {
    height: auto;
}

.badge.total-days {
    position: relative;
    top: -4px;
    left: 10px;
    margin-bottom: -8px;
    display: inline-block;

    @include media-breakpoint-down(md) {
        left: -20px;
    }
}

.status-item {
    text-transform: uppercase;
    // font-weight: bold;
    font-size: 12px;
}

.last-status-description {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 11px;
    font-weight: bold;
}
.action-button-wrap {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    .cancel-notification {
        position: absolute;
        top: 5px;
        background: red;
        bottom: 5px;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        left: 0px;
        right: 0px;
        z-index: 1;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        color: white;
        display: none;
        border-radius: 50%;
        font-size: 14px;
    }

    &:hover {
        .cancel-notification {
            display: flex;
        }
    }

    .timer {
        display: flex;
        width: 40px;
        height: 40px;
        background: #ff9800;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        margin: 5px auto 0 auto;
        border-radius: 50%;
    }

    // .cancel-button {
    //     background-color: red;
    // }
}

.pagination-wrap {
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
    .pagination-buttons {
        border: none;
        font-weight: bold;
        width: 30px;
        height: 30px;
        margin-right: 3px;
        margin-left: 3px;
        border-radius: 3px;
        background: #f3f3f3;
        color: #353535;
        margin-bottom: 5px;
        margin-top: 5px;

        &.current {
            background: #4a4a4a;
            color: white;
        }
    }
}

.main-panel {
    min-height: 100vh;

    @include media-breakpoint-up(lg) {
        &.small {
            width: calc(100% - 70px);
        }
    }

    .content {
        margin-top: 0px;
        padding: 0 30px 10px 20px;

        @include media-breakpoint-down(md) {
            padding: 0 10px 0px 10px;
        }
    }
}

.sidebar[data-active-color="danger"] .nav li.active > a,
.sidebar[data-active-color="danger"] .nav li.active > a i {
    color: #0f359c;
}

.sidebar .nav {
    font-weight: bold;
}

textarea:focus,
input:focus,
button:focus {
    outline: none;
}

.btn {
    cursor: pointer;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: rgb(2, 0, 36);
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(228, 228, 254, 1) 100%
    );
    flex-direction: column;

    .login-logo {
        img {
            width: 150px;
            margin-bottom: 5px;
        }
    }
    label {
        color: gray;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 5px;
    }

    input.form-controller {
        padding-left: 20px;
    }

    form {
        width: 100%;
        max-width: 500px;
        padding: 10px;
    }

    .login-box {
        width: 100%;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 50px;
        padding-bottom: 20px;
        box-shadow: 0px 0px 5px #cecece;
        margin-top: 15px;
    }

    .login-input-wrap {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        position: relative;

        label {
            margin-top: 10px;
            padding-left: 15px;
        }

        .eye {
            position: absolute;
            right: 13px;
            bottom: 8px;
            font-size: 18px;
            cursor: pointer;
        }
    }

    .form-controller {
        border-radius: 22px;
        min-width: 280px;
    }

    .btn-primary {
        border-radius: 22px;
    }
}

.btn-primary {
    padding: 10px 5px;
    border: none;
    background-color: royalblue;
    color: white;
    border-radius: 5px;
    min-width: 100px;
    font-weight: bold;
}

.btn-primary.sent {
    padding: 16px 5px;
    border: none;
    background-color: #ffffff;
    color: #274acd;
    border-radius: 3px;
    min-width: 85px;
    font-weight: bold;
    height: 100%;
}

input.form-controller {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 3px;
}

.breadcrumb {
    background-color: transparent;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 0;
    padding-bottom: 0;
    padding: 0;
    font-weight: bold;

    .fas {
        margin-right: 5px;
    }
}

.notification-tick {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    background-color: red;
    position: absolute;
    font-size: 12px;
    top: 10px;
    right: 123px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 9991;
    background-color: #000000bf !important;
}
.ReactModal__Content.ReactModal__Content--after-open {
    height: 250px;

    @include media-breakpoint-down(md) {
        height: auto;
    }
}

.MuiTooltip-tooltipPlacementBottom {
    font-size: 14px !important;
    font-weight: bold !important;
    letter-spacing: 0.5px;
}

.triggered-note {
    padding: 10px;
    background: #4caf50;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}

.extend-session-btn {
    border: none;
    background-color: #03a9f4;
    padding: 10px;
    color: white;
    float: right;
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 200px;
}

.close-modal-button {
    border: none;
    width: 200px;
    border-radius: 5px;
    background-color: rgb(51, 51, 51);
    padding: 10px;
    color: white;
    float: right;
}

.notification-wrap {
    width: 300px;
    background: #e4e4e4;
    position: absolute;
    right: 5px;
    z-index: 5;
    padding: 10px;
    border-radius: 0px;
    overflow: auto;
    font-size: 13px;
    display: none;

    &.open {
        display: block;
    }

    .title {
        margin-bottom: 5px;
    }

    .notification-item {
        background-color: white;
        cursor: pointer;
        padding: 10px;
        margin-bottom: 5px;
    }
}

.merge-fields {
    background: white;
    margin: 0 30px;
    border-radius: 5px;
    padding: 30px;

    .instruction.p {
        font-size: 14px;
        color: gray;
        margin-bottom: 0;
    }

    .grid {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .merge-field-input {
        border: 1px solid #d8d8d8;
        border-radius: 3px;
        padding: 10px 10px;
        width: 100%;
        color: #676767;
        font-size: 14px;
    }

    .input-wrapper {
        margin-top: 10px;
        position: relative;

        input {
            padding-left: 20px;
        }

        .fas.fa-angle-left {
            position: absolute;
            left: 10px;
            bottom: 14px;
        }

        .fas.fa-angle-right {
            position: absolute;
            right: 10px;
            bottom: 14px;
        }
    }

    .btn-primary {
        width: 150px;
    }

    .merge-field-label {
        font-weight: bold;
        text-transform: uppercase;
        padding-left: 5px;
        font-size: 12px;
    }
}

.data-table-wrap {
    // max-height: calc(100vh - 180px);
}

.history {
    .data-table-wrap {
        max-height: calc(100vh - 230px);

        @media screen and (max-width: 1527px) {
            max-height: calc(100vh - 290px);
        }

        @include media-breakpoint-down(lg) {
            max-height: calc(100vh - 290px);
        }

        @include media-breakpoint-down(md) {
            max-height: calc(100vh - 206px);
        }

        @include media-breakpoint-down(sm) {
            max-height: calc(100vh - 206px);
        }
    }
}

.btn-send-msg {
    // background-color: #ffffff;
    // color: black;
}

.dashboard-wrap {
    .MuiTableContainer-root {
        height: calc(100vh - 260px);

        @include media-breakpoint-down(md) {
            height: calc(100vh - 315px);
        }
    }

    .mail-icon {
        cursor: pointer;
        text-align: center;
        color: red;
    }
}

.msg-right {
    background-color: white;
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

.data-table-title {
    font-size: 15px;
    margin-bottom: 0;
    font-weight: bold;
    margin-left: 10px;
}

table {
    th {
        font-weight: bold;
    }
    .MuiInput-underline:before {
        display: none;
    }

    .notification-icon {
        width: 20px;
        margin-bottom: -5px;
    }

    .notification-icon-wrap {
        padding: 10px;
        text-align: center;
        color: white;
        border-radius: 3px;
        width: 55px;
        margin-left: 7px;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        cursor: pointer;
    }
}

.app-loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // background: #00000038;
    color: gray;
    z-index: 10;
}

.App {
    .Toastify__toast {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        padding-left: 20px;
        border-radius: 5px;
    }

    .Toastify__toast--success {
        background-color: #8acb87 !important;
    }
}
